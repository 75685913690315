<template>
  <UserDialog :inline="true" :user-id="userId" />
</template>

<script>
import UserDialog from "@/components/users/UserDialog"

export default {
  components: {
    UserDialog,
  },
  async asyncData({ params }) {
    return {
      userId: params.id,
    }
  },
}
</script>
